.collaboration{
    padding: 4rem 1rem;
    position: relative;


    h6{
        font-size: 1.35rem;
        line-height: 1.9rem;
    }
    
    .center{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .header{
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: center;
        h4{
            font-weight: normal;
            font-size: 1.5rem;
        }
        img{
            width: 350px;
            object-fit: contain;
        }
        .shakes{
            text-align: center;
            img{
                margin-top: -10px;
                width: 250px;
            }
        }
    }

    .body{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        max-width: 1200px;
        margin: 1rem auto;
        @media screen and (max-width: 800px) {
            flex-direction: column;
        }
        .image{
            position: relative;
            .rosa{
                position: absolute;
                top: 30px;
                left: -38px;
            }
        }

        .main{
            p{
                text-align: left;
                width: 80%;
            }
            ul{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                text-align: left;
                gap: 1rem;
                font-weight: normal;
                color: #074B60;
            }
            @media screen and (max-width: 800px) {
                width: 100%;
                p{
                    width: 100%;
                 }
                 ul{
                    font-size: 0.9rem;
                 }
             }
        }
    }

    .left{
        margin-left: -15%;
        @media screen and (max-width: 800px) {
            margin-left: 0;
        }
        
    }

    .list{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        text-align: left;
        .item{
            display: flex;
            gap: 1rem;
            align-items: center;
            p{
                font-size: 1rem;
            }
            img{
                width: 50px;
                height: 50px;
                object-fit: contain;
                transform: scale(0.9);
            }
        }
    }

    .flowers{
        width: 400px;
        height: auto;
        position: absolute;
        right: 1rem;
        bottom: 0;
        img{
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
         @media screen and (max-width: 800px) {
             width: 250px;
             bottom: -30px;
         }
    }
    @media screen and (max-width: 800px) {
        .header{
            flex-direction: column;
        }
    }
}