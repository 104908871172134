@import '../../Styles/shared.scss';

.container{
  position: fixed;
  height: 250px;
  width: 400px;
  background-color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  top: 150px;
  right: -340px;
  cursor: pointer;
  transition: .65s all cubic-bezier(.58,-0.13,.54,1.15);
  -webkit-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.25); 
  -moz-box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.25);
  z-index: 50;

  @media (max-width:700px){
    display: none;
  }

  &.open{
    right: 0;
    // height: 250px;

    .tabDiv{
      opacity: 0;
      pointer-events: none;
    }
  }

  .tabDiv{
    // width: 400px;
    // height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    position: absolute;
    left: -47px;
    top: 115px;
    transition: .5s all ease;

    h6{
      font-size: 18px;
      font-weight: 700;
      margin: 0;
      margin-left: 5px;
      position: relative;
      top: -2px;
    }

    .icon{
      background: url('../../Assets/homepage/featureIcons/chat_dark.svg') no-repeat center/contain;
      height:35px;
      width:35px;
      transform: rotate(90deg);
    }
  }

  .content{
    height:100%;
    width:100%;
    box-sizing:border-box;
    padding:20px 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: .5s all ease;
    opacity: 0;
    pointer-events: none;

    &.show{
      opacity: 1;
      pointer-events: all;
    }

    h5.title{
      font-size: 24px;
      padding-left: 50px;
      background: url('../../Assets/homepage/featureIcons/chat_dark.svg') no-repeat left;
      background-size: 40px;
      margin: 0 auto 5px auto;
      width: fit-content;
    }


    p.bodyText{
      font-size: 15px;
      color: #82A0A4;
      font-weight: 300;
      line-height: 20px;
    }

    .bottom{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      *{
        margin: 0;
      }

      .left{
        display: flex;
        opacity: 1;
        align-items: center;

        p{
          line-height: 20px;
          font-weight: 300;
          color: #858585;
          font-size: 13px;
          text-align: left;
          
          &.name{
            color: #333333;
            font-weight: 700;
            font-size: 17px;
          }
        }

        img,
        .placeholder{
          height:45px;
          width:45px;
          border-radius: 50%;
          object-fit: contain;
          margin-right: 10px;
        }
        

      }

      .actionBtn{
        font-size: 14px;
        padding:10px 15px;
        color: white;
        background-size: 15px;
        background-color: $faith-pink;
        height:fit-content;
        width:fit-content;
        border-radius: 40px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        cursor: pointer;
        transition: .3s all ease;
      
        *{
          white-space: nowrap;
        }
      
        &:hover{
          background-color: #ff6055;
        }
      }
    }

  }

}